import React from 'react';
import { Controller } from 'react-hook-form';
import { useFormContext } from '../../Form'; // Make sure this import is correct
import * as styles from './styles.module.css'; // Make sure this import is correct
import SelectWithPopover from './SelectWithPopover/SelectWithPopover'; // Make sure this import is correct

const SelectField = ({name, label, rules, options}) => {
  const { control, errors } = useFormContext();
  return (
    <div className="mb-[2.5rem]">
      <label htmlFor={name} className={styles.label_form}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({field}) => (
          <SelectWithPopover
            onChange={field.onChange}
            options={options}
          />
        )}
      />
      {errors[name] && <p className={styles.label_error}>{errors[name].message}</p>}
    </div>
  );
};

export default SelectField;
