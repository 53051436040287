import React from 'react';
import Layout from '../../components/Layout';
import Title from '../../components/Title/Title';
import Form from '../../components/Form/Form';
import GlobalWrapper from '../../components/GlobalWrapper/GlobalWrapper';
import SelectField from '../../components/Form/Fields/SelectField/SelectField';
import InputField from '../../components/Form/Fields/InputField/InputField';
import CheckboxField from '../../components/Form/Fields/CheckboxField/CheckboxField';
import SubmitButton from '../../components/Form/SubmitButton/SubmitButton';
import ExitLink from '../../components/ExitLink/ExitLink';
import CtaDescList from "../../components/CtaDescList/CtaDescList";
import Footnotes from '../../components/Footnotes/Footnotes';

import { GtagHead } from '../../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead/>
      <title>Request a Rep | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description' content='Request a representative for questions on LOQTORZI®'/>
    </>
  )
}

const ctaDescriptionListData = [
    {
        cta: {
            text: <>LOQTORZI<sup>®</sup> at a glance</>,
            url:'/loqtorzi-at-a-glance/'
        },
        text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
            to help you <br className='desktop:hidden' />support your patients.</div>
    }, {
        cta: {
            text: <span>Interactive <br/>
        Prescribing Information </span>,
            url:'/loqtorzi-prescribing-information/'
        },
        text: <div>Explore LOQTORZI<sup>®</sup> data
            <br className='desktop:hidden' /> in the interactive
            <br className='desktop:hidden' /> Prescribing Information.</div>
    },
    {
        cta: {
            text: 'Get Updates',
            url: '/get-updates'
        },
        text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
    }
];

const specialties = [
    'Oncologist',
    'Nurse navigator/Infusion nurse',
    'Pharmacist',
    'Office staffer',
];

export default function RequestARep() {
  return (
    <Layout>
      <Title pageName={'Request A Rep'}>
        Request a <br className='tablet:hidden'/>
        representative
      </Title>
      <GlobalWrapper className={'border-left'}>
        <h2 className='h3 pl-[1rem] mb-[1.2rem] text-left normal-case'>
            Whether you have questions about <br className='desktop:hidden'/>
            LOQTORZI<sup>®</sup> or need resources or <br className='desktop:hidden'/>
            support material, we are here to help <br className='desktop:hidden'/>
            you and your office staff
        </h2>
        <Form endpoint="/backend/submit.php" ThankYouURL="/request-a-rep/confirmation">
          <h4 className='mb-[1.7rem]'>Fill in your information below:</h4>

          <SelectField
              name="specialty"
              label="Select the option that best describes your occupation."
              options={specialties}
          />

          <InputField
              name="firstName"
              label="First name*"
              rules={{required: 'Enter your first name.'}}
          />

          <InputField
              name="lastName"
              label="Last name*"
              rules={{required: 'Enter your last name.'}}
          />

          <InputField
              name="email"
              label="Email*"
              rules={{
                  required: 'Enter your email address.',
                  pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                  },
              }}
          />

          <InputField
              name="zipCode"
              label="ZIP code*"
              rules={{
                  required: 'Enter your ZIP code.',
                  pattern: {
                      value: /^\d{5}(-\d{4})?$/,
                      message: 'Invalid zip code',
                  },
              }}
          />

          <Footnotes className={'mt-[2rem]'} items={[{
            bullet: '*',
            text: <span>Required field.</span>
          },{
            bullet: '',
            text: <span>R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
          }]} />

          <div className="my-[2.5rem]">
            <div className="text-[1.6rem]">
              <p>
                <strong>Uses and Disclosure of Personal Information</strong><br/>
                  I authorize Coherus Biosciences, Inc. and its contractors and business partners
                  (&ldquo;Coherus&rdquo;) to use and/or disclose my personal information, including my
                personal health information, only for the following purposes:
              </p>

              <ul className="list-disc ml-[1.25rem]">
                <li className='mb-[1rem]'>To enroll me in, and/or continue my participation in
                    Coherus&rsquo; informational updates program;
                </li>
                <li className='mb-[1rem]'>To provide me with informational and marketing materials
                    relating to Coherus products and services, and/or a condition or treatment;
                </li>
                <li className='mb-[1rem]'>To improve, develop, and evaluate products, services,
                    materials and programs related to a condition or treatment.
                </li>
                <li className='mb-[1rem]'>I understand that I may be contacted by mail, email, and/or
                    telephone for any of these purposes. I further understand that the Coherus News
                    Updates and additional information and marketing communications are optional and
                    free services. I do not have to sign this authorization and this authorization in no
                    way affects my right to obtain any medications. To obtain a copy of this
                    authorization or to cancel at any time I can contact Coherus by calling
                    1-888-728-0419. The Coherus Privacy Policy can be found at <ExitLink
                        url='https://www.coherus.com/privacy-policy/'
                        className='no-underline'>https://www.coherus.com/privacy-policy/</ExitLink>. My
                    consent is subject to all the terms and conditions stated in our privacy policy. You
                    can make requests to know what personal information we have collected from you in
                    the past 12 months or requests to delete your personal information via <ExitLink
                        url='http://www.reguesteasy.com/See3-7108'
                        className='no-underline'>http://www.reguesteasy.com/See3-7108</ExitLink> or
                    1-888-728-0419.
                </li>
              </ul>

              <p>
                <strong>LIMITATION OF LIABILITY</strong><br/>
                All the services, information, software, and other materials provided through the portal
                are provided on an &ldquo;as-is&rdquo; basis and without any express or implied
                warranties. While our database uses Blockchain security features, we do not guarantee
                the security of the database and connected portal(s) or the services or the prevention
                from loss of, alteration of, or improper access to, your account information or data. To
                the maximum extent permitted by law, under no circumstances shall Coherus, its officers,
                directors, employees, subsidiaries, or affiliated companies be liable for any direct,
                indirect, incidental, special, consequential, or punitive damages, such as, but not
                limited to, loss of revenue, loss of anticipated profits, goodwill, diminution of value,
                business interruption costs, or any other intangible losses arising out of damage from
                any security breach or any other security intrusion of personal information.
              </p>

              <p>
                By checking the &quot;I accept&quot; box, I am stating electronically that I have read
                  and understood Coherus&#39; personal information terms (above in its full text), that I
                  am at least 18 years old and authorize Coherus Biosciences, Inc., its contractors, and
                  its business partners to use and disclose my Personal Information for the purposes
                  described above.
              </p>

              <p>
                In addition to the above consent, I understand that by checking this box and signing, I
                  consent to Coherus calling and texting me at any of the methods of communication I have
                  provided with promotional communications relating to Coherus product and services and/or
                  my condition or treatment. Coherus may use automatic dialing machines or artificial or
                  prerecorded messages to contact me and may leave a voicemail or SMS/text message
                  (standard text messaging rates may apply). I understand that I am not to provide this
                  consent as a condition of purchasing any goods or services. I have the ability to reply
                  STOP to cancel messages at anytime.
              </p>

              <p>
                This is a security feature to enhance your protection.<br/>
              </p>
            </div>
          </div>

          <CheckboxField
              name="accept"
              label="I accept"
              rules={{required: 'Please select the checkbox to continue.'}}
          />

          <SubmitButton>Submit</SubmitButton>
        </Form>
      </GlobalWrapper>
      <CtaDescList content={ctaDescriptionListData} />
    </Layout>
  );
}