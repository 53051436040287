import React, { useState } from 'react';
import ChevronImg from '../../../../../images/chevron.inline.svg';
import clsx from 'clsx';
import * as styles from './SelectWithPopover.module.css';

const SelectWithPopover = ({options, onChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setOption] = useState(false);
  const handleTogglePopover = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div>
      <div className="relative mt-2">
        <button
          type="button"
          onClick={handleTogglePopover}
          className={styles.selector_btn}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-labelledby="listbox-label"
        >
          <span className="block truncate">{selectedOption}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronImg className={isOpen ? 'rotate-180' : ''} />
          </span>
        </button>
        <div className={styles.input_underline}/>
        {isOpen && (
          <ul
            className={styles.selector_list}
            aria-labelledby="listbox-label"
            tabIndex="-1"
          >
            {options.map((option) => (
              <li key={option}>
                <button className={clsx(
                  styles.selector_item,
                  selectedOption === option && 'bg-gray-200'
                )}
                onClick={() => handleSelectOption(option)}
                role="option"
                aria-selected="true">
                  <span className="font-normal block truncate">{option}</span>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectWithPopover;
